// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');
require('jquery-ui');
require('packs/direct_uploads.js');
require('packs/google_analytics.js.erb');
require('packs/jquery.tagsinput.min.js');
require('packs/slick.js');
require('packs/helper/nav-toggle');
require('bootstrap');

import("controllers");
import("@hotwired/turbo-rails");

import("trix")
import("@rails/actiontext")

//= require uikit
//= require uikit-components

// require("packs/tag-it.min.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on('turbo:load', function () {
	$('#upload_tag_list').tagsInput({
		defaultText: 'Add video tags',
		height: 'auto',
		width: 'auto',
	});

	$('.toggleTagEdit').click(function () {
		$('.edit_upload').toggleClass('active');
	});

	$('#life_session_tag_list').tagsInput({
		height: 'auto',
		width: 'auto',
	});

	$('.zoomControls .in').click(function () {
		let zoomLevel = $('video').attr('zoomLevel');
		zoomLevel = parseInt(zoomLevel);
		if (zoomLevel < 7) {
			zoomLevel = zoomLevel + 1;
			$('video').attr('class', `_${zoomLevel}`);
			$('video').attr('zoomLevel', zoomLevel);
			$('video').draggable();
			$('video').draggable('enable');
			$('.videoContainer .arrow').css('display', 'block');
		}
	});

	$('.zoomControls .out').click(function () {
		let zoomLevel = $('video').attr('zoomLevel');
		zoomLevel = parseInt(zoomLevel);
		if (zoomLevel > 1) {
			zoomLevel = zoomLevel - 1;
			$('video').attr('class', `_${zoomLevel}`);
			$('video').attr('zoomLevel', zoomLevel);
		}
		if (zoomLevel === 1) {
			$('video').draggable('disable');
			$('video').css('left', '0px');
			$('video').css('top', '0px');
			$('.videoContainer .arrow').css('display', 'none');
			$('video').css({ marginTop: '0' });
			$('video').css({ marginLeft: '0' });
		}
	});

	$('.videoContainer .arrow').click(function () {
		let direction = $(this).attr('direction'),
			height = $('.videoContainer').height();
		$('.videoContainer').css('height', `${height}px`);
		var upPosition = 0,
			leftPosition = 0;
		if (direction === 'up') {
			$('video').css({ marginTop: '-=30px' });
		} else if (direction === 'down') {
			$('video').css({ marginTop: '+=30px' });
		} else if (direction === 'left') {
			$('video').css({ marginLeft: '-=30px' });
		} else if (direction === 'right') {
			$('video').css({ marginLeft: '+=30px' });
		}
	});

	$('.frameControls .back').click(function () {
		let video = $('video')[0],
			frameTime = 1 / 25;
		video.currentTime = Math.max(0, video.currentTime - frameTime);
	});

	$('.frameControls .next').click(function () {
		let video = $('video')[0],
			frameTime = 1 / 25;
		video.currentTime = Math.min(video.duration, video.currentTime + frameTime);
	});

	$('.editTitle').click(function () {
		$('.editFormContainer').toggleClass('active');
		$('.title').toggleClass('currentlyEditing');
	});

	$('.createBookMark').click(function () {
		$('.createBookMarkContainer').toggleClass('active');
		let currentTime = $('video')[0].currentTime;
		$('#book_mark_bookmark_time').val(currentTime);
	});

	$('.bookMarkTime').click(function () {
		let bookMark = $(this).attr('value');
		$('video')[0].currentTime = bookMark;
	});

	$('.lifeSessionsOptions .tool').click(function () {
		$('.tool').removeClass('active');
		$(this).addClass('active');
		let dataMode = $(this).attr('data-mode');
		$('.sessionElement').removeClass('active');
		$(`[data-mode="${dataMode}"]`).addClass('active');
	});

	$('.toggleCalendarTypeContainer .calendarType').click(function () {
		$('.calendarType').removeClass('active');
		let calendarType = $(this).attr('calendarType');
		$('.calendarContainerContent').removeClass('active');
		$(`[calendarType="${calendarType}"]`).addClass('active');
	});

	$('.editSocialMedia').click(function () {
		$('.editSocialMediaContainer').toggleClass('active');
	});

	$('.lifeSessionsShowContainer .editTags').click(function () {
		$('.tagFormContainer').toggleClass('active');
	});

	$('.adminTab').click(function () {
		$('.adminTab').removeClass('active');
		$(this).addClass('active');
	});

	$('.copyEmails').click(function () {
		let emails = '';
		$('.email').each(function () {
			emails = emails + $(this).text() + '\n';
		});
		copyToClipboard(emails);
	});

	$('.copyEmailsActive').click(function () {
		let emails = '';
		$('.email-active').each(function () {
			emails = emails + $(this).text() + '\n';
		});
		copyToClipboard(emails);
	});

	$('.copyEmailsHistory').click(function () {
		let emails = '';
		$('.email-history').each(function () {
			emails = emails + $(this).text() + '\n';
		});
		copyToClipboard(emails);
	});

	$('.clickToUpload').click(function () {
		$('.uploadForm.artwork').toggleClass('active');
	});

	$('.clickToUploadReference').click(function () {
		$('.uploadForm.reference').toggleClass('active');
	});

	$(window).keyup(function (e) {
		if (e.keyCode === 44) {
			copyToClipboardDisabled();
		} else if (e.keyCode === 27) {
			$('.lifeSessionPopUp').removeClass('active');
		}
	});

	$('#plan_id').change(function () {
		let tier = $(this).val();
		$('.subscriberContainer').removeClass('active');
		$(`.subscriberContainer[tier='${tier}']`).addClass('active');
	});

	let tier = $('#plan_id').val();
	$(`.subscriberContainer[tier='${tier}']`).addClass('active');

	$('.toggleSessionsRemainingEdit').click(function () {
		$(this).next('.sessionsRemainingEditContainer').toggleClass('active');
	});

	$('.togglePlanOptions').click(function () {
		$(this).next('.planOptions').toggleClass('active');
	});

	// Tooltips
	// Requires Bootstrap 3 for functionality
	$('.js-tooltip').tooltip();

	// Copy to clipboard
	// Grab any text in the attribute 'data-copy' and pass it to the
	// copy function
	$('.js-copy').click(function () {
		var text = $(this).attr('data-copy');
		var el = $(this);
		copyPasscode(text, el);
	});
});

function copyToClipboard(text) {
	if (window.clipboardData && window.clipboardData.setData) {
		// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
		return clipboardData.setData('Text', text);
	} else if (
		document.queryCommandSupported &&
		document.queryCommandSupported('copy')
	) {
		var textarea = document.createElement('textarea');
		textarea.textContent = text;
		textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
		document.body.appendChild(textarea);
		textarea.select();
		try {
			return document.execCommand('copy'); // Security exception may be thrown by some browsers.
		} catch (ex) {
			console.warn('Copy to clipboard failed.', ex);
			return false;
		} finally {
			document.body.removeChild(textarea);
		}
	}
}

function copyToClipboardDisabled() {
	var aux = document.createElement('input');
	aux.setAttribute('value', 'print screen disabled!');
	document.body.appendChild(aux);
	aux.select();
	document.execCommand('copy');
	// Remove it from the body
	document.body.removeChild(aux);
	console.log('Print screen disabled!');
}

function copyPasscode(text, el) {
	var copyTest = document.queryCommandSupported('copy');
	var elOriginalText = el.attr('data-original-title');

	if (copyTest === true) {
		var copyTextArea = document.createElement('textarea');
		copyTextArea.value = text;
		document.body.appendChild(copyTextArea);
		copyTextArea.select();
		try {
			var successful = document.execCommand('copy');
			var msg = successful ? 'Copied!' : 'Whoops, not copied!';
			el.attr('data-original-title', msg).tooltip('show');
		} catch (err) {
			console.log('Oops, unable to copy');
		}
		document.body.removeChild(copyTextArea);
		el.attr('data-original-title', elOriginalText);
	} else {
		// Fallback if browser doesn't support .execCommand('copy')
		window.prompt('Copy to clipboard: Ctrl+C or Command+C, Enter', text);
	}
}

$(document).ready(function () {
	$('.edit-settings-modal').on('show.bs.modal', function () {
		$('.profilepg-userinfo-wrap').css('z-index', 2000);
	})

	$('.edit-settings-modal').on('hide.bs.modal', function () {
		$('.profilepg-userinfo-wrap').css('z-index', 1000);
	})

	if ($('.edit-settings-modal').length > 0) {
		$('.edit-settings-modal').modal("show");
	}
});
